<template>
  <div class="w-100">
    <h5 class="mb-0">{{ $t('myFormation') }}</h5>
    <hr>
    <div class="table-responsive">
      <table class="table table-hover table-striped table-bordered">
        <tbody>
          <tr>
            <td class="text-center font-weight-bold">{{ $tc('goalkeeper', 2) }}</td>
          </tr>
          <tr v-for="player in gks" :key="`gk-${player.id}`">
            <td class="text-center">
              <router-link :to="{ name: 'PlayerHome', params: { lang: lang, console: console, playerSlug: player.slug } }">{{ player.nick }}</router-link>
            </td>
          </tr>
          <tr>
            <td class="text-center font-weight-bold">{{ $t('defense') }}</td>
          </tr>
          <tr v-for="player in defenses" :key="`defense-${player.id}`">
            <td class="text-center">
              <router-link :to="{ name: 'PlayerHome', params: { lang: lang, console: console, playerSlug: player.slug } }">{{ player.nick }}</router-link>
            </td>
          </tr>
          <tr>
            <td class="text-center font-weight-bold">{{ $t('midfielders') }}</td>
          </tr>
          <tr v-for="player in midfielders" :key="`mid-${player.id}`">
            <td class="text-center">
              <router-link :to="{ name: 'PlayerHome', params: { lang: lang, console: console, playerSlug: player.slug } }">{{ player.nick }}</router-link>
            </td>
          </tr>
          <tr>
            <td class="text-center font-weight-bold">{{ $t('front') }}</td>
          </tr>
          <tr v-for="player in sts" :key="`st-${player.id}`">
            <td class="text-center">
              <router-link :to="{ name: 'PlayerHome', params: { lang: lang, console: console, playerSlug: player.slug } }">{{ player.nick }}</router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      sts: [],
      gks: [],
      midfielders: [],
      defenses: [],
      teamId: this.$route.params.teamId
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'lang',
      'console',
      'team',
      'user'
    ])
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      const params = {
        tournament: this.$route.query.tournament
      }
      const path = `auth/${this.lang}/${this.console}/teams/${this.teamId}/my-formation`
      this.$axios.get(path, { params }).then(response => {
        const data = response.data
        this.gks = data.gks
        this.defenses = data.defenses
        this.midfielders = data.midfielders
        this.sts = data.fronts
      })
    }
  }
}
</script>
